import { alpha, Box, Chip, SxProps, Theme, Tooltip } from "@mui/material";
import { SharingGroup, Team } from "certiblok-api-manager";

import CertiblokIcon from "./icons/CertiblokIcon";

const GroupChip = ({
	group,
	onDelete,
	sx,
}: {
	group: SharingGroup | Team;
	onDelete?: (group: SharingGroup | Team) => void;
	sx?: SxProps<Theme>;
}) => {
	return (
		<Tooltip title={group.name}>
			<Chip
				label={group.name}
				color="secondary"
				icon={
					<Box sx={{ borderRadius: "50%", backgroundColor: "white", flexShrink: 0 }}>
						<Box
							sx={{ borderRadius: "50%", backgroundColor: alpha(group.color, 0.24), width: "20px", height: "20px" }}
							className="flex items-center justify-center"
						>
							<CertiblokIcon name="profile_outline_01" color={group.color} size={16} />
						</Box>
					</Box>
				}
				sx={{
					width: "100%",
					justifyContent: "space-between",
					textAlign: "start",
					"& span": {
						flexGrow: 1,
					},
					...sx,
				}}
				onDelete={Boolean(onDelete) ? () => onDelete?.(group) : undefined}
			/>
		</Tooltip>
	);
};

export default GroupChip;
