import { autoUpdate, flip, FloatingPortal, offset, size as floatingSize, useFloating } from "@floating-ui/react";
import { Spinner } from "@mabi-ui/spinner";
import { cn } from "@mabi-ui/utils";
import { alpha, Avatar, Box, TextField, TextFieldProps, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { SearchApi, SharingGroup, SharingGroupApi, UserOrContact } from "certiblok-api-manager";
import { useCombobox } from "downshift";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import avatarPlaceholder from "../../../../../assets/avatar-placeholder.png";
import { backendMediaUrlParser } from "../../../../../utils";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { IQueryFilter, transformToStringFrom } from "../../../../../utils/components/tables/AdminTable";
import throttle from "lodash/throttle";
import { useApi } from "../../../../../utils/hooks/api";

type GroupContactUserAutocompleteType = {
	onSelectUser?: (user: UserOrContact) => void;
	onSelectGroup?: (group: SharingGroup) => void;
	onSelectEmail?: (email: string) => void;
	includeGroups?: boolean;
	includeEmails?: boolean;
	disabledUsersOrContactsIds?: string[];
	disabledEmails?: string[];
	disabledSharingGroupsIds?: string[];
	TextFieldProps?: TextFieldProps;
	className?: string;
	optionsClassName?: string;
	isLoading?: boolean;
};

export const isSharingGroup = (option: UserOrContact | SharingGroup): option is SharingGroup => {
	return (option as SharingGroup).userIds !== undefined;
};

const GroupContactUserAutocomplete = ({
	includeGroups = false,
	includeEmails = false,
	onSelectUser,
	onSelectGroup,
	onSelectEmail,
	disabledUsersOrContactsIds = [],
	disabledSharingGroupsIds = [],
	disabledEmails = [],
	TextFieldProps,
	className,
	optionsClassName,
	isLoading,
}: GroupContactUserAutocompleteType) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [inputValue, setInputValue] = useState<string>("");
	const [throttledInputValue, setThrottledInputValue] = useState<string>("");

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const throttleInput = useCallback(
		throttle(
			(value: string) => {
				setThrottledInputValue(value);
			},
			500,
			{ trailing: true, leading: false }
		),
		[]
	);

	const searchApi = useApi(SearchApi);
	const sharingGroupApi = useApi(SharingGroupApi);

	const { data: members, isLoading: isLoadingMembers } = useQuery({
		queryKey: ["sharingGroups", "userOrContact", throttledInputValue],
		queryFn: async () => {
			const res: (UserOrContact | SharingGroup)[] = [
				...(includeGroups
					? (
							await sharingGroupApi.getAllSharingGroups(
								transformToStringFrom({ page: 0, perPage: 100, orderBy: "name" } as IQueryFilter)
							)
					  )?.data ?? []
					: []
				).filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase())),
				...((await searchApi.searchUsersOrContacts(inputValue))?.data ?? []),
			];

			return res;
		},
		initialData: [],
		keepPreviousData: true,
		enabled: inputValue.length >= 3,
	});

	const { refs, floatingStyles } = useFloating({
		placement: "bottom",
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(4),
			flip({ padding: 10 }),
			floatingSize({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width}px`,
					});
				},
			}),
		],
	});

	const { isOpen, getInputProps, getItemProps, getMenuProps, highlightedIndex } = useCombobox({
		items: includeEmails ? [inputValue, ...members] : members,
		onInputValueChange: ({ inputValue }) => {
			setInputValue(inputValue);
			throttleInput(inputValue);
		},
		itemToString: (item) => "",
		selectedItem: null,
		onSelectedItemChange: ({ selectedItem }) => {
			if (typeof selectedItem === "string") {
				onSelectEmail?.(selectedItem.toLowerCase());
			} else if (isSharingGroup(selectedItem)) {
				onSelectGroup?.(selectedItem);
			} else {
				onSelectUser?.(selectedItem);
			}
		},
	});

	return (
		<div className={cn("relative", className)} ref={refs.setReference}>
			<TextField
				fullWidth
				variant="standard"
				size={"small"}
				color={"secondary"}
				{...TextFieldProps}
				{...getInputProps()}
				InputProps={{
					sx: {
						"&.Mui-focused": {
							outlineWidth: "2px",
							outlineStyle: "solid",
							outlineColor: theme.palette.secondary.main,
						},
					},
					placeholder: t("contactsGroups.searchAmongContacts"),
					className: cn("bg-secondary/[0.06] px-3 py-2 items-center flex [&>input]:pb-0"),
					endAdornment: isLoading ? (
						<Spinner className="w-4 h-4 border-2 border-secondary border-t-transparent" />
					) : (
						<CertiblokIcon name="search_outline" size={16} color="rgba(0,0,0,0.6)" />
					),
					...TextFieldProps?.InputProps,
				}}
			/>
			<FloatingPortal>
				<div
					className="box-border z-[3000] "
					{...getMenuProps({}, { suppressRefError: true })}
					ref={refs.setFloating}
					style={floatingStyles}
				>
					{isOpen && !isLoadingMembers && !isLoading && (
						<ul
							className={cn(
								"m-0 px-2 py-2 bg-white shadow-8 flex flex-col gap-3 rounded-xl max-h-[200px] overflow-y-scroll",
								optionsClassName
							)}
						>
							{includeEmails && inputValue.length > 0 && members.every((m) => isSharingGroup(m) || m.email !== inputValue) && (
								<li
									className={cn(
										"list-none m-0 py-2 px-3 rounded-xl min-h-[40px] h-10 box-border flex items-center",
										isEmail(inputValue) && !disabledEmails.includes(inputValue)
											? "cursor-pointer active:bg-secondary/[0.12]"
											: "bg-grey/[0.06] pointer-events-none",
										highlightedIndex === 0 && isEmail(inputValue) && !disabledEmails.includes(inputValue) && "bg-secondary/[0.06]"
									)}
									{...getItemProps({ item: inputValue, index: 0 })}
								>
									<div className="flex gap-3 items-center">
										<div className="w-6 h-6 flex items-center justify-center">
											<CertiblokIcon name="mail_outline" color={"secondary"} size={20} />
										</div>

										<Typography
											variant="body2"
											className={cn(
												isEmail(inputValue) && !disabledEmails.includes(inputValue)
													? "text-secondary-dark"
													: "text-secondary-dark/[0.64]"
											)}
											noWrap
										>
											{inputValue}
										</Typography>
									</div>
								</li>
							)}
							{(!includeEmails || inputValue.length === 0) && members.length === 0 ? (
								inputValue.length >= 3 ? (
									<span>
										<Typography variant="caption" className="px-3 font-semibold italic">
											{t("global.noResult")}
										</Typography>
									</span>
								) : (
									<span>
										<Typography variant="caption" className="px-3 font-semibold">
											{t("contactsGroups.insertAtLeastThreeChars")}
										</Typography>
									</span>
								)
							) : (
								members.map((option, i) => {
									const isDisabled = isSharingGroup(option)
										? disabledSharingGroupsIds.includes(option.id)
										: disabledUsersOrContactsIds.includes(option.id);
									const index = includeEmails ? i + 1 : i;
									return (
										<li
											key={option.id}
											className={cn(
												"list-none m-0 py-2 px-3 rounded-xl h-10 box-border flex items-center",
												isDisabled ? "bg-grey/[0.06] pointer-events-none" : "cursor-pointer active:bg-secondary/[0.12]",
												highlightedIndex === index && !isDisabled && "bg-secondary/[0.06]"
											)}
											{...getItemProps({ item: option, index: index })}
										>
											{isSharingGroup(option) ? (
												<div className="flex gap-3 items-center w-full">
													<Box
														className="w-6 h-6 rounded-full flex items-center justify-center"
														sx={{ backgroundColor: alpha(option.color, 0.24) }}
													>
														<CertiblokIcon name="profile_outline_01" color={option.color} size={20} />
													</Box>
													<div className="flex flex-col overflow-hidden w-full">
														<Typography variant="body2" className={cn(isDisabled ? "text-black/[0.64]" : "text-black/[0.8]")} noWrap>
															{option.name}
														</Typography>
														<Typography
															variant="label"
															noWrap
															component={"p"}
															className="min-w-0 max-w-full overflow-hidden truncate text-secondary-dark"
														>
															{(option.users ?? []).map((user, i) => {
																return `${user.name} ${user.surname}${i === (option?.users ?? []).length - 1 ? "" : ", "}`;
															})}
														</Typography>
													</div>
												</div>
											) : (
												<div className="grid grid-cols-2 items-center justify-between gap-3 w-full">
													<div className="flex gap-3 items-center">
														<Avatar
															src={backendMediaUrlParser(option?.profileUrl) ?? avatarPlaceholder}
															sx={{ width: 24, height: 24 }}
														/>
														<Typography
															variant="body2"
															className={cn(isDisabled ? "text-black/[0.64]" : "text-black/[0.8]")}
															noWrap
														>{`${option.name} ${option.surname}`}</Typography>
													</div>
													<Typography
														variant="caption"
														className="text-secondary-dark w-full"
														noWrap
													>{`${option.email}`}</Typography>
												</div>
											)}
										</li>
									);
								})
							)}
						</ul>
					)}
				</div>
			</FloatingPortal>
		</div>
	);
};

export default GroupContactUserAutocomplete;
