import NiceModal from "@ebay/nice-modal-react";
import { cn } from "@mabi-ui/utils";
import { Button, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import type { Folder } from "certiblok-api-manager";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import EditCreateFolderModal, {
	EditCreateFolderModalProps,
} from "../../../pages/Folders/components/EditCreateFolderModal/EditCreateFolderModal";
import SingleFolderToChoose from "../../../pages/Folders/components/UploadDocument/components/SingleFolderToChoose";
import { AssignToFolderContext } from "../../../pages/Inbox/controllers/AssignToFolderContext";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import CertiblokIcon from "../icons/CertiblokIcon";
import Loading from "../Loading";
import { useModalNavigator } from "../ModalNavigator/ModalNavigator";
import NoMoreFolders from "../NoMoreFolders";
import type { useFolderPicker } from "./controllers/useFolderPicker";

NiceModal.register("edit-create-folder-modal", EditCreateFolderModal);

type FolderPickerProps = {
	folderPickerControls: ReturnType<typeof useFolderPicker>;
	selectedFolder?: Folder;
	onFolderSelect: (folder: Folder) => void;
	shouldStartFetching?: boolean;
};

const GROUP_SIZE = 6;

const FolderPicker: React.FC<FolderPickerProps> = ({
	folderPickerControls,
	selectedFolder,
	shouldStartFetching,
	onFolderSelect,
}) => {
	const { availableFolders, loading, fetchFoldersById, fetchFolders, clearAvailableFolders } = folderPickerControls;
	const assignToFolderContext = useContext(AssignToFolderContext);

	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	useEffect(() => {
		if (shouldStartFetching) {
			if (selectedFolder) {
				fetchFoldersById(selectedFolder.id);
			} else {
				fetchFolders();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFolder, shouldStartFetching]);

	const { pushModalById } = useModalNavigator();

	const groupedFolders = useMemo(() => {
		const groups: Folder[][] = [];
		for (let i = 0; i < availableFolders.length; i += GROUP_SIZE) {
			groups.push(availableFolders.slice(i, i + GROUP_SIZE));
		}
		return groups;
	}, [availableFolders]);

	const renderSingleFolderToChoose = useCallback(
		(folder: Folder) => {
			return (
				<SingleFolderToChoose
					key={folder.id}
					folder={folder}
					onClick={() => {
						clearAvailableFolders();
						onFolderSelect(folder);
					}}
				/>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onFolderSelect]
	);

	return (
		<div className="max-w-[100vw]">
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 6 }}>
				<Typography variant="label">{t("moveDocuments.cooseFolder")}</Typography>
				<Button
					color="secondary"
					size="small"
					onClick={() => {
						pushModalById<EditCreateFolderModalProps>("edit-create-folder-modal", {
							parentFolderId: selectedFolder?.id,
						}).then(() => {
							if (selectedFolder) {
								fetchFoldersById(selectedFolder.id, { forceReinit: true });
							} else {
								fetchFolders({ forceReinit: true });
							}
						});
					}}
				>
					<CertiblokIcon size={24} color="inherit" name={"add"} />
					{t("global.createNewFolder")}
				</Button>
			</Box>
			{isMd ? (
				<div className="w-full box-border h-[250px]">
					<div className={cn("flex gap-6 h-[250px] lg:gap-12 w-full overflow-x-auto overflow-y-auto horizontal-scroll")}>
						{groupedFolders.map((group, index) => {
							return (
								<div key={index} className="flex flex-col gap-2 basis-[25%] max-w-[25%] flex-shrink-0">
									{group.map((folder) => {
										return renderSingleFolderToChoose(folder);
									})}
									{group.length < GROUP_SIZE && loading && <Loading title={t("moveDocuments.foldersLoading")} sx={{ py: 2 }} />}
								</div>
							);
						})}
						{availableFolders.length % GROUP_SIZE === 0 && loading && (
							<div className={cn("flex items-center justify-center h-full", availableFolders.length === 0 && "w-full")}>
								<Loading title={t("moveDocuments.foldersLoading")} sx={{ py: 2 }} />
							</div>
						)}
						{availableFolders.length === 0 && !loading && (
							<div className="w-full h-full flex items-center justify-center">
								<NoMoreFolders />
							</div>
						)}
					</div>
				</div>
			) : (
				<div
					className={cn(
						"flex flex-col gap-2 h-[30vh] overflow-y-auto",
						assignToFolderContext.foldersHistory?.length > 0 && "h-[20vh]",
						((loading && availableFolders.length === 0) || availableFolders.length === 0) && "justify-center"
					)}
				>
					{availableFolders.length > 0 ? (
						availableFolders.map((folder) => {
							return renderSingleFolderToChoose(folder);
						})
					) : loading ? null : (
						<NoMoreFolders />
					)}

					<Collapse in={loading}>
						<Loading
							sx={{ py: 2 }}
							circularProgressProps={{
								size: 20,
							}}
							title={t("moveDocuments.foldersLoading")}
						/>
					</Collapse>
				</div>
			)}
		</div>
	);
};
export default FolderPicker;
