import { circularProgressClasses, linearProgressClasses, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircularProgessWithPercentage from "../../../../../CircularProgessWithPercentage";
import LinearProgressWithPercentage from "../../../../../LinearProgressWithPercentage";
import { usePlanUsage } from "./hooks/usePlanUsage";

type SideBarUsageProps = {
	open?: boolean;
};
const SideBarUsage: React.FC<SideBarUsageProps> = ({ open }) => {
	const { t } = useTranslation();
	const { planUsage } = usePlanUsage();

	const usedPercentage = planUsage ? Math.round((planUsage.usedStorage / planUsage.totalStorage) * 100 * 100) / 100 : 0;

	return planUsage ? (
		<Stack
			sx={{
				marginTop: 6,
				marginBottom: 1,
				width: "100%",
			}}
		>
			<Typography
				children="Storage"
				variant="subtitle2"
				sx={{ opacity: 0.74, ...(!open && { width: "100%", textAlign: "center" }) }}
			/>
			{open ? (
				<LinearProgressWithPercentage
					percentage={usedPercentage}
					sx={{
						[`& .${linearProgressClasses.bar}`]: {
							backgroundColor: usedPercentage === 100 ? "error.main" : "blue.main",
						},
					}}
				/>
			) : (
				<CircularProgessWithPercentage
					percentage={usedPercentage}
					color="secondary"
					size={78}
					containerSx={{ marginTop: 2, width: "auto" }}
					thickness={3}
					sx={{
						color: usedPercentage === 100 ? "error.main" : "blue.main",
						[`& .${circularProgressClasses.circle}`]: {
							strokeLinecap: "round",
						},
					}}
				/>
			)}
			{open && (
				<div className="flex flex-col gap-1">
					<Typography
						children={`${planUsage.usedStorage} ${t("profile.GBOfSpaceUsed", { totalSpace: planUsage.totalStorage })}`}
						variant="caption"
						sx={{ opacity: 0.74 }}
					/>
					{(planUsage?.usedDwgPreview ?? 0) > 0 && <div className="h-[1px] bg-primary-a38 w-full rounded-full" />}
					{(planUsage?.usedDwgPreview ?? 0) > 0 && (
						<Typography variant="caption" sx={{ opacity: 0.74 }}>
							{planUsage?.usedDwgPreview ?? 0}
							{` ${t("profile.totalDwgPreview", { count: planUsage?.totalDwgPreview ?? 0 })}`}
						</Typography>
					)}
				</div>
			)}
		</Stack>
	) : null;
};
export default SideBarUsage;
