"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyApi = exports.CompanyApiFactory = exports.CompanyApiFp = exports.CompanyApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * CompanyApi - axios parameter creator
 * @export
 */
const CompanyApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"update\", \"ParticipationCompany\"]
         * @summary Approve or reject participation | Permission required: [\"update\", \"ParticipationCompany\"]
         * @param {string} id
         * @param {InputApproveOrRejectParticipation} [inputApproveOrRejectParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrRejectParticipation: (id, inputApproveOrRejectParticipation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('approveOrRejectParticipation', 'id', id);
            const localVarPath = `/company/participation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputApproveOrRejectParticipation, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Company\"]
         * @summary Ask participation | Permission required: [\"readAll\", \"Company\"]
         * @param {InputAskParticipation} [inputAskParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askParticipation: (inputAskParticipation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/participation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputAskParticipation, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Change manager | Permission required: [\"update\", \"Company\"]
         * @param {InputChangeManager} [inputChangeManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeManager: (inputChangeManager, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/changeManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputChangeManager, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"User\"]
         * @summary Create new company | Permission required: [\"readOne\", \"User\"]
         * @param {InputCreateNewCompany} [inputCreateNewCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCompany: (inputCreateNewCompany, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateNewCompany, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete items
         * @param {Array<DeleteItemsRequest>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItems: (requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Edit company | Permission required: [\"update\", \"Company\"]
         * @param {InputEditCompany} [inputEditCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompany: (inputEditCompany, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditCompany, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all company admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyAdmins: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/admin/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all user linked companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserLinkedCompanies: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/linked/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get company with members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyWithMembers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get members for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersForCompany: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/member/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Get participations for company | Permission required: [\"update\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipationsForCompany: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/participation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Invite member to company
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inviteMemberToCompany: (uNKNOWNBASETYPE, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/member/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(uNKNOWNBASETYPE, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Leave company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveCompany: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove member from company
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromCompany: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('removeMemberFromCompany', 'id', id);
            const localVarPath = `/company/member/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Revoke participation
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeParticipation: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('revokeParticipation', 'id', id);
            const localVarPath = `/company/participation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Search companies
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanies: (qry, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set company
         * @param {InputSetCompany} [inputSetCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompany: (inputSetCompany, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/company/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputSetCompany, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CompanyApiAxiosParamCreator = CompanyApiAxiosParamCreator;
/**
 * CompanyApi - functional programming interface
 * @export
 */
const CompanyApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.CompanyApiAxiosParamCreator)(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"ParticipationCompany\"]
         * @summary Approve or reject participation | Permission required: [\"update\", \"ParticipationCompany\"]
         * @param {string} id
         * @param {InputApproveOrRejectParticipation} [inputApproveOrRejectParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrRejectParticipation(id, inputApproveOrRejectParticipation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.approveOrRejectParticipation(id, inputApproveOrRejectParticipation, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Company\"]
         * @summary Ask participation | Permission required: [\"readAll\", \"Company\"]
         * @param {InputAskParticipation} [inputAskParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askParticipation(inputAskParticipation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.askParticipation(inputAskParticipation, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Change manager | Permission required: [\"update\", \"Company\"]
         * @param {InputChangeManager} [inputChangeManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeManager(inputChangeManager, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.changeManager(inputChangeManager, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"User\"]
         * @summary Create new company | Permission required: [\"readOne\", \"User\"]
         * @param {InputCreateNewCompany} [inputCreateNewCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCompany(inputCreateNewCompany, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createNewCompany(inputCreateNewCompany, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete items
         * @param {Array<DeleteItemsRequest>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItems(requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteItems(requestBody, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Edit company | Permission required: [\"update\", \"Company\"]
         * @param {InputEditCompany} [inputEditCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompany(inputEditCompany, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editCompany(inputEditCompany, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all company admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyAdmins(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllCompanyAdmins(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all user linked companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserLinkedCompanies(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllUserLinkedCompanies(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get company with members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyWithMembers(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCompanyWithMembers(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get members for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersForCompany(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMembersForCompany(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Get participations for company | Permission required: [\"update\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipationsForCompany(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getParticipationsForCompany(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Invite member to company
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inviteMemberToCompany(uNKNOWNBASETYPE, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.inviteMemberToCompany(uNKNOWNBASETYPE, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Leave company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveCompany(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.leaveCompany(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove member from company
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromCompany(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeMemberFromCompany(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Revoke participation
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeParticipation(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.revokeParticipation(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Search companies
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanies(qry, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchCompanies(qry, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set company
         * @param {InputSetCompany} [inputSetCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompany(inputSetCompany, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setCompany(inputSetCompany, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.CompanyApiFp = CompanyApiFp;
/**
 * CompanyApi - factory interface
 * @export
 */
const CompanyApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.CompanyApiFp)(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"ParticipationCompany\"]
         * @summary Approve or reject participation | Permission required: [\"update\", \"ParticipationCompany\"]
         * @param {string} id
         * @param {InputApproveOrRejectParticipation} [inputApproveOrRejectParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrRejectParticipation(id, inputApproveOrRejectParticipation, options) {
            return localVarFp.approveOrRejectParticipation(id, inputApproveOrRejectParticipation, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Company\"]
         * @summary Ask participation | Permission required: [\"readAll\", \"Company\"]
         * @param {InputAskParticipation} [inputAskParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askParticipation(inputAskParticipation, options) {
            return localVarFp.askParticipation(inputAskParticipation, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Change manager | Permission required: [\"update\", \"Company\"]
         * @param {InputChangeManager} [inputChangeManager]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeManager(inputChangeManager, options) {
            return localVarFp.changeManager(inputChangeManager, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"User\"]
         * @summary Create new company | Permission required: [\"readOne\", \"User\"]
         * @param {InputCreateNewCompany} [inputCreateNewCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCompany(inputCreateNewCompany, options) {
            return localVarFp.createNewCompany(inputCreateNewCompany, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete items
         * @param {Array<DeleteItemsRequest>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItems(requestBody, options) {
            return localVarFp.deleteItems(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Edit company | Permission required: [\"update\", \"Company\"]
         * @param {InputEditCompany} [inputEditCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompany(inputEditCompany, options) {
            return localVarFp.editCompany(inputEditCompany, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all company admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyAdmins(options) {
            return localVarFp.getAllCompanyAdmins(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all user linked companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserLinkedCompanies(options) {
            return localVarFp.getAllUserLinkedCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get company with members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyWithMembers(options) {
            return localVarFp.getCompanyWithMembers(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get members for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersForCompany(options) {
            return localVarFp.getMembersForCompany(options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Company\"]
         * @summary Get participations for company | Permission required: [\"update\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipationsForCompany(options) {
            return localVarFp.getParticipationsForCompany(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Invite member to company
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inviteMemberToCompany(uNKNOWNBASETYPE, options) {
            return localVarFp.inviteMemberToCompany(uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Leave company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveCompany(options) {
            return localVarFp.leaveCompany(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove member from company
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromCompany(id, options) {
            return localVarFp.removeMemberFromCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Revoke participation
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeParticipation(id, options) {
            return localVarFp.revokeParticipation(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search companies
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanies(qry, options) {
            return localVarFp.searchCompanies(qry, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set company
         * @param {InputSetCompany} [inputSetCompany]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompany(inputSetCompany, options) {
            return localVarFp.setCompany(inputSetCompany, options).then((request) => request(axios, basePath));
        },
    };
};
exports.CompanyApiFactory = CompanyApiFactory;
/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
class CompanyApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"update\", \"ParticipationCompany\"]
     * @summary Approve or reject participation | Permission required: [\"update\", \"ParticipationCompany\"]
     * @param {string} id
     * @param {InputApproveOrRejectParticipation} [inputApproveOrRejectParticipation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    approveOrRejectParticipation(id, inputApproveOrRejectParticipation, options) {
        return (0, exports.CompanyApiFp)(this.configuration).approveOrRejectParticipation(id, inputApproveOrRejectParticipation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Company\"]
     * @summary Ask participation | Permission required: [\"readAll\", \"Company\"]
     * @param {InputAskParticipation} [inputAskParticipation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    askParticipation(inputAskParticipation, options) {
        return (0, exports.CompanyApiFp)(this.configuration).askParticipation(inputAskParticipation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Company\"]
     * @summary Change manager | Permission required: [\"update\", \"Company\"]
     * @param {InputChangeManager} [inputChangeManager]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    changeManager(inputChangeManager, options) {
        return (0, exports.CompanyApiFp)(this.configuration).changeManager(inputChangeManager, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"User\"]
     * @summary Create new company | Permission required: [\"readOne\", \"User\"]
     * @param {InputCreateNewCompany} [inputCreateNewCompany]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    createNewCompany(inputCreateNewCompany, options) {
        return (0, exports.CompanyApiFp)(this.configuration).createNewCompany(inputCreateNewCompany, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete items
     * @param {Array<DeleteItemsRequest>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    deleteItems(requestBody, options) {
        return (0, exports.CompanyApiFp)(this.configuration).deleteItems(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Company\"]
     * @summary Edit company | Permission required: [\"update\", \"Company\"]
     * @param {InputEditCompany} [inputEditCompany]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    editCompany(inputEditCompany, options) {
        return (0, exports.CompanyApiFp)(this.configuration).editCompany(inputEditCompany, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all company admins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    getAllCompanyAdmins(options) {
        return (0, exports.CompanyApiFp)(this.configuration).getAllCompanyAdmins(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all user linked companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    getAllUserLinkedCompanies(options) {
        return (0, exports.CompanyApiFp)(this.configuration).getAllUserLinkedCompanies(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get company with members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    getCompanyWithMembers(options) {
        return (0, exports.CompanyApiFp)(this.configuration).getCompanyWithMembers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get members for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    getMembersForCompany(options) {
        return (0, exports.CompanyApiFp)(this.configuration).getMembersForCompany(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Company\"]
     * @summary Get participations for company | Permission required: [\"update\", \"Company\"]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    getParticipationsForCompany(options) {
        return (0, exports.CompanyApiFp)(this.configuration).getParticipationsForCompany(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Invite member to company
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    inviteMemberToCompany(uNKNOWNBASETYPE, options) {
        return (0, exports.CompanyApiFp)(this.configuration).inviteMemberToCompany(uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Leave company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    leaveCompany(options) {
        return (0, exports.CompanyApiFp)(this.configuration).leaveCompany(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove member from company
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    removeMemberFromCompany(id, options) {
        return (0, exports.CompanyApiFp)(this.configuration).removeMemberFromCompany(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Revoke participation
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    revokeParticipation(id, options) {
        return (0, exports.CompanyApiFp)(this.configuration).revokeParticipation(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Search companies
     * @param {string} [qry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    searchCompanies(qry, options) {
        return (0, exports.CompanyApiFp)(this.configuration).searchCompanies(qry, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set company
     * @param {InputSetCompany} [inputSetCompany]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    setCompany(inputSetCompany, options) {
        return (0, exports.CompanyApiFp)(this.configuration).setCompany(inputSetCompany, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CompanyApi = CompanyApi;
