/** @jsxImportSource @emotion/react */

import { Accordion, AccordionDetails, AccordionSummary, Button, Fade, Tooltip, Typography } from "@mui/material";
import * as MenuBar from "@radix-ui/react-menubar";
import clsx from "clsx";
import type React from "react";
import { Fragment, ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { RouteInfos } from "..";
import CertiblokIcon, { CertiblokIconNames } from "../../../../icons/CertiblokIcon";

type DrawerRouteButtonProps = {
	selected: boolean;
	open: boolean;
	expanded: boolean;
	onExpand: () => void;
	onClick: () => void;
} & RouteInfos;

const ParentComponent = ({
	children,
	subroutes,
	isOpen,
	onOpenChange,
}: {
	children: ReactNode;
	subroutes?: RouteInfos[];
	isOpen: boolean;
	onOpenChange: () => void;
}) => {
	const location = useLocation();

	return (
		<>
			{Boolean(subroutes) ? (
				<MenuBar.Root
					value={isOpen ? "open" : ""}
					onValueChange={() => {
						onOpenChange();
					}}
				>
					<MenuBar.Menu value={"open"}>
						<MenuBar.Trigger asChild>{children}</MenuBar.Trigger>
						<MenuBar.Portal>
							<MenuBar.Content
								className="bg-background min-w-[200px] flex flex-col p-2 pl-0 z-[9999] border-[1px] border-background border-solid rounded-r-2xl relative"
								align="start"
								side="left"
								sideOffset={31}
								alignOffset={-4}
							>
								{subroutes?.map((route) => {
									const subrouteSelected =
										route.path !== "/audit_rooms"
											? (location.pathname + location.search).includes(route.path ?? "")
											: route.path === location.pathname;
									return (
										<MenuBar.Item asChild key={route.title}>
											<Button
												href={route.path}
												className={twMerge(
													clsx(
														"hover:bg-black-a06 active:bg-black-a12 flex gap-3 items-center justify-between text-black font-normal hover:font-bold hover:tracking-normal",
														subrouteSelected && "bg-white hover:bg-white active:bg-white font-bold tracking-normal"
													)
												)}
											>
												{route.title}
												<Fade in={subrouteSelected}>
													<div className="w-2 h-2 rounded-full bg-primary flex-shrink-0" />
												</Fade>
											</Button>
										</MenuBar.Item>
									);
								})}
							</MenuBar.Content>
						</MenuBar.Portal>
					</MenuBar.Menu>
				</MenuBar.Root>
			) : (
				<Fragment>{children}</Fragment>
			)}
		</>
	);
};

export const RouteButton: React.FC<DrawerRouteButtonProps> = ({
	open,
	onClick,
	selected,
	iconName,
	title,
	path,
	subroutes,
	expanded,
	onExpand,
}) => {
	const correctedIconName = selected ? iconName?.replace("outline", "bold") : iconName;
	const location = useLocation();

	const isCategory = Boolean(subroutes);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	return (
		<Tooltip title={title} enterDelay={500} disableInteractive>
			{isCategory && open ? (
				// <Fade in={isCategory && open} mountOnEnter unmountOnExit onAnimationStart={onExpand}>
				<div>
					<Accordion className="[&.MuiPaper-root]:py-0" expanded={expanded && open} onChange={() => onExpand()}>
						<AccordionSummary
							expandIcon={<CertiblokIcon name="▾-arrow-3" color="black" />}
							className={twMerge(
								clsx(
									"active:bg-black-a12 bg-inherit rounded-2xl transition-colors focus-visible:bg-primary-a12 [&_.MuiAccordionSummary-content]:m-0 [&_.MuiAccordionSummary-content]:flex [&_.MuiAccordionSummary-content]:gap-3 [&_.MuiAccordionSummary-content]:items-center hover:bg-black-a06 flex-row [&_.MuiAccordionSummary-expandIconWrapper.Mui-expanded]:rotate-180",
									selected && "bg-white hover:bg-white active:bg-white"
								)
							)}
						>
							<div
								className={twMerge(
									clsx("box-border rounded-xl p-1 h-8 w-8 transition-colors", selected ? "bg-background" : "bg-inherit")
								)}
							>
								<CertiblokIcon
									className={clsx(selected ? "text-primary" : "text-black")}
									name={correctedIconName as CertiblokIconNames}
								/>
							</div>

							<Fade in={open} mountOnEnter unmountOnExit>
								<Typography variant="subtitle2">{title}</Typography>
							</Fade>
						</AccordionSummary>

						{open && (
							<AccordionDetails className="px-0 pl-7 py-2">
								<div className="">
									{subroutes?.map((route) => {
										const routeSelected =
											route.path !== "/audit_rooms"
												? (location.pathname + location.search).includes(route.path ?? "")
												: route.path === location.pathname;
										return (
											<Button
												key={route.title}
												disableRipple
												className={twMerge(
													clsx(
														"flex gap-[10px] justify-start items-center w-full text-start text-sm font-normal text-black hover:bg-black-a06 active:bg-black-a12 hover:font-bold active:font-bold hover:tracking-normal active:tracking-normal",
														routeSelected && "bg-white hover:bg-white active:bg-white font-bold tracking-normal"
													)
												)}
												href={route.path}
												onClick={onClick}
											>
												<Fade in={routeSelected}>
													<div className="w-2 h-2 rounded-full bg-primary flex-shrink-0" />
												</Fade>
												{route.title}
											</Button>
										);
									})}
								</div>
							</AccordionDetails>
						)}
					</Accordion>
				</div>
			) : (
				// </Fade>

				<div className={twMerge(clsx(open ? "w-full" : "w-fit"))}>
					<ParentComponent
						isOpen={isMenuOpen}
						onOpenChange={() => setIsMenuOpen((p) => !p)}
						subroutes={subroutes}
						children={
							<Button
								disableRipple
								className={twMerge(
									clsx(
										"relative w-full h-12 rounded-2xl flex-shrink-0 text-black transition-all hover:bg-black-a06 active:bg-black-a12",
										selected && "bg-white font-bold text-primary hover:bg-white active:bg-white",
										isMenuOpen && !selected && "bg-black-a12 hover:bg-black-a12",
										!open && "max-w-[48px] min-w-0"
									)
								)}
								onClick={onClick}
								href={path}
							>
								{/* <Fade in={open} unmountOnExit mountOnEnter> */}
								{open ? (
									<div className="box-border absolute w-full h-full flex items-center justify-center gap-3 p-2">
										<div
											className={twMerge(
												clsx("box-border rounded-xl p-1 h-8 w-8 transition-colors", selected ? "bg-background" : "bg-inherit")
											)}
										>
											<CertiblokIcon
												className={clsx(selected ? "text-primary" : "text-black")}
												name={correctedIconName as CertiblokIconNames}
											/>
										</div>

										<Fade in={open} mountOnEnter unmountOnExit>
											<div className="flex-grow text-start">{title}</div>
										</Fade>
									</div>
								) : (
									<div className={twMerge(clsx("w-8 h-8 p-1 box-border rounded-lg ", selected && "bg-background"))}>
										<CertiblokIcon
											className={clsx(selected ? "text-primary" : "text-black")}
											name={correctedIconName as CertiblokIconNames}
										/>
									</div>
								)}
							</Button>
						}
					/>
				</div>
			)}
		</Tooltip>
	);
};
